import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import EventCard from "../../components/EventCard";
import { PartyMode } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [events, setEvents] = useState([]);
  const [response, setResponse] = useState(false);

  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };


  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint.
    axios.get('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/events-list/')
      .then((response) => {
        console.log(response.data.data.results);
        setEvents(response.data.data.results);
        setResponse(true)
      })
      .catch((error) => {
        console.error('Error fetching Events:', error);
      });
  }, []);

  const handleDeleteSuccess = () => {
    // Display a success toast
    toast.success('Event Deleted Successfully', {
      position: 'top-right',
      autoClose: 1000,
    });
  };

  const deleteEvent = (identifier) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Event?');
    if (isConfirmed) {
    axios.delete(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/event-details/${identifier}/`,{
      headers: {
        'Token': `${getAuthToken()}`,
        'Authorization': `Token ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
    })
  .then(response => {
    console.log('Event deleted successfully',response);
    setEvents((prevEvents) => prevEvents.filter((event) => event.event_identifier !== identifier));
    handleDeleteSuccess()
  })
  .catch(error => {
    console.error('Error deleting Event', error);
  });
}
}



  return (
    <Box m="20px" className="main-dashboard">
      {/* HEADER */}

      {
       !response ? (
          <div className="custom_loader">Loading data...</div>
        ) 
      :

      (
        <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Registered Events" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* <Box
          gridColumn="span 1"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom="10px"
          padding="5px"
        > */}
          
          <div style={{ display: 'flex', flexDirection: 'column',gap:'10px',maxWidth:"100%",padding:"5px 10px" }}>
          {events.map((event)=>(
            <EventCard
            key={event.id}
            event={event.title}
            details={event.description}
            place={event.place}
            city={event.address}
            from_date={event.date}
            to_date={event.date}
            time={event.time}
            images={event.images}
            priceCategory={event.ticket_types}
            id={event.id}
            identifier={event.event_identifier}
            deleteEvent = {() => deleteEvent(event.event_identifier) }
         icon={
           <PartyMode 
           sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
           />
          }
          />
          
          ))}
          </div>
 

        {/* </Box> */}

        </>
      )}

    </Box>
  );
};

export default Dashboard;
