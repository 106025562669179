import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { PartyMode } from "@mui/icons-material";
import CheckerCard from "../../components/CheckerCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';


const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [checkers, setCheckers] = useState([]);
  const [response, setResponse] = useState(false);

  
  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  const handleSuccess = () => {
    // Display a success toast
    toast.success('Checker Deleted Successfully', {
      position: 'top-right',
      autoClose: 2000,
    });
    
  };

  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint.
    axios.get(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/list-checkers/`,{
      headers: {
        'Token': `${getAuthToken()}`,
        'Authorization': `Token ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response.data);
        const activeCheckers = response.data.data.filter((checker) => checker.is_active == true)
        setCheckers(response.data.data);
        setResponse(true)
      })
      .catch((error) => {
        console.error('Error fetching checker:', error);
      });
  }, []);


  const deleteChecker = (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Checker?');
    if (isConfirmed) {
      axios.delete(`https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/delete-checker/${id}/`,{
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      })
    .then(response => {
      setCheckers((prevCheckers) => prevCheckers.filter((checker) => checker.checker_id !== id));
      handleSuccess()
      console.log('Checker deleted successfully',response);
    })
    .catch(error => {
      console.error('Error deleting Coupon', error);
    });
  }
  }


  

  return (
    <Box m="20px" className="main-dashboard">

{
       !response ? (
          <div className="custom_loader">Loading data...</div>
        ) 
      :

      (
        <>
      <Header title="CHECKERS" subtitle="View available checkers"/>
      <div style={{ display: 'flex', flexDirection: 'column',gap:'10px',maxWidth:"100%",padding:"5px 10px" }}>
          {checkers.length == 0 ?
          (
            <div className="no_data_container_left">No Data!</div>
          ) :
          checkers.map((checker,i)=>(
            <CheckerCard
            key={i}
            event={checker.event_title}
            name={checker.checker_name}
            email={checker.checker_email}
            id={checker.checker_id}
            deleteChecker = {()=>deleteChecker(checker.checker_id)}
         icon={
           <PartyMode 
           sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
           />
          }
          />
          
          ))}
          </div>
          </>
      )}
    </Box>
  );
};

export default Team;
