import { Box, Button, TextField, Select, MenuItem, Typography, useTheme  } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";  
import axios from 'axios';
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import InputLabel from "@mui/material/InputLabel";
import { toast } from 'react-toastify';

const EditCoupon = () => {

  const [fromTime, setFromTime] = useState()
  const [toTime, setToTime] = useState()
  const [toDate, setToDate] = useState()
  const [fromDate, setFromDate] = useState()
    
  let { id } = useParams();
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #191a1a  inset" };

  function convertCustomFormatToISO8601(customFormat) {
    // Assuming customFormat is in the format "YYYY-MM-DDhh:mm"
    
    const year = customFormat.slice(0, 4);
    const month = customFormat.slice(5, 7);
    const day = customFormat.slice(8, 10);
    const hour = customFormat.slice(10, 12);
    const minute = customFormat.slice(13, 15);
    
    // Create a Date object with the parsed components
    const date = new Date(year, month - 1, day, hour, minute);
    
    // Convert the Date object to ISO 8601 format
    const iso8601Date = date.toISOString();
    
    return iso8601Date;
  }

  function convertToYYYYMMDD(customFormat) {
    const date = new Date(customFormat);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function convertISO8601ToTime(iso8601Date) {
    const date = new Date(iso8601Date);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  



  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const reader = new FileReader();
  const [citys, setCitys] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [coupons, setCoupon] = useState({code:""});
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [base64Image, setBase64Image] = useState('');

  const [eventType, setEventType] = useState([{ type: '', price: '' }]);

  const handleEventType = () => {
    setEventType([...eventType, { type: '', price: '' }]);
  };

  const [artists, setArtists] = useState([{ artist: '', artist_url: null }]);
  const [images, setImages] = useState(['']);
  const [layoutImage, setLayoutImage] = useState('');

  const handleAddArtist = () => {
    setArtists([...artists, { artist: '', artist_url: null }]);
  };

  const handleAddImage = () => {
    setImages([...images, '']);
  };

  const handleLayoutImageChange = async (event) => {
    const { files } = event.target;

      try {
        var currentImage = files[0];
        const formData = new FormData();
        formData.append('file', currentImage);

        const response = await axios.post(
          'https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/upload-file/',
          formData,
          {
            headers: {
              'Token': `${getAuthToken()}`,
              'Authorization': `Token ${getAuthToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      
        // console.log("return image ", response.data.data.download_url);
        setLayoutImage(response.data.data.download_url)
      
        // Handle successful response here
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with a non-2xx status code
          console.error("Server responded with an error status:", error.response.status);
          console.error("Error response data:", error.response.data);
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received from the server");
        } else {
          // Something else went wrong
          console.error("Error:", error.message);
        }
      }
  
  };


  useEffect(() => { 

    axios.get('https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/coupons-list/', {
      headers: {
        'Token': `${getAuthToken()}`,
        'Authorization': `Token ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // Successfully fetched data
        console.log(response.data.data);
    
        // Filtering the results to find the coupon with a specific ID
        const [selectedCoupon] = response.data.data.filter((coupon) => {
          return coupon.id == id;
        });
    
        // Logging the selected coupon and setting it in the state
        console.log("og value", selectedCoupon);
        setCoupon(selectedCoupon);
        setFromDate(convertToYYYYMMDD(selectedCoupon.valid_from))
        setFromTime(convertISO8601ToTime(selectedCoupon.valid_from))
        setToTime(convertISO8601ToTime(selectedCoupon.valid_to))
        setToDate(convertToYYYYMMDD(selectedCoupon.valid_to))
      })
      .catch((error) => {
        // Handling errors
        console.error('Error fetching Coupons:', error);
      });
    
    



  }, []);


//   const [initialCity, setInitialCity] = useState(events.city);
// //   console.log("city..........",initialCity);
//   const [selectedCity, setSelectedCity] = useState(''); // Initialize the user's selection


//   useEffect(() => {
//     // When events.city changes, update the initialCity
//     setInitialCity(events.city);
//   }, [events.city]);

//   const handleCityChange = (event) => {
//     setSelectedCity(event.target.value);
//   };

  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  const getLoginId = () => {
    return JSON.parse(localStorage.getItem('loginId'));
  };


  const handleInputChange = (index, event, fieldName) => {
    const { value } = event.target;
    const newEventType = [...eventType];
    newEventType[index][fieldName] = value;
    setEventType(newEventType);
  };

  
 
 

  const handleUpdateChange = (e) => {
    // Update the state with the new value when the input changes
    setCoupon({ ...coupons, [e.target.name]: e.target.value });
  };


  


  const handleSuccess = () => {
    // Display a success toast
    toast.success('Coupons Updated Successfully', {
      position: 'top-right',
      autoClose: 1000,
    });
  
    setTimeout(()=>{
      window.location='/view_coupons';
  },1000)
  };
  

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values) => {
    try {
      const clonedValues = JSON.parse(JSON.stringify(coupons));
      clonedValues.created_by = getLoginId()
      clonedValues.valid_from = convertCustomFormatToISO8601(fromDate + fromTime);
      clonedValues.valid_to = convertCustomFormatToISO8601(toDate + toTime);
      console.log('Submitted Data:', clonedValues);
      


      // Make a POST request to the API endpoint to create an event
      const response = await axios.patch(
        `https://fourband-prod-on2dz.ondigitalocean.app/v1/data/dashboard/coupon-details/${id}/`,clonedValues,
        {
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Check if the response status is successful (e.g., HTTP 200)
      if (response.status === 200 || response.status === 201) {
        console.log('Update Response:', response.data);
        handleSuccess()
      } else {
        // Handle unexpected response status codes
        console.error('Unexpected response:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network or request errors
      console.error('Error:', error);

      // Check if the error is due to unauthorized access (401)
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        // You might want to redirect the user to the login page here
      } else {
        // Handle other errors
        alert('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <Box m="20px" className="event_form main-dashboard">
      <Header title="UPDATE COUPON" subtitle="Update Here" />

      {
        coupons.code == "" ? (
          <div className="custom_loader">Loading data...</div>
        )
          :

        (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleFormSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Code"
                onBlur={handleBlur}
                onChange={handleUpdateChange}
                value={coupons.code}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={touched.code && errors.code}
                sx={{
                  gridColumn: "span 4",
                
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
        {coupons.discount_percentage && (
              
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Discound Percentage"
                onBlur={handleBlur}
                onChange={handleUpdateChange}
                value={coupons.discount_percentage}
                name="discount_percentage"
                error={!!touched.discount_percentage && !!errors.discount_percentage}
                helperText={touched.discount_percentage && errors.discount_percentage}
                sx={{
                  gridColumn: "span 2",
                
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
    
            )}

        {coupons.discount_value && (
                  
                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Discound Value"
                onBlur={handleBlur}
                onChange={handleUpdateChange}
                value={coupons.discount_value}
                name="discount_value"
                error={!!touched.discount_value && !!errors.discount_value}
                helperText={touched.discount_value && errors.discount_value}
                sx={{
                  gridColumn: "span 2",
                
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

        )}

            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="limit"
                onBlur={handleBlur}
                onChange={handleUpdateChange}
                value={coupons.limit}
                name="limit"
                error={!!touched.limit && !!errors.limit}
                helperText={touched.limit && errors.limit}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='time']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />



              

              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="From Date"
                onBlur={handleBlur}
                onChange={(e)=>{setFromDate(e.target.value)}}
                value={fromDate}
                name="valid_from"
                error={!!touched.valid_from && !!errors.valid_from}
                helperText={touched.valid_from && errors.valid_from}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="To Date"
                onBlur={handleBlur}
                onChange={(e)=>{setToDate(e.target.value)}}
                value={toDate}
                name="valid_to"
                error={!!touched.valid_to && !!errors.valid_to}
                helperText={touched.valid_to && errors.valid_to}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />


              <TextField
                fullWidth
                variant="filled"
                type="time"
                label="From Time"
                onBlur={handleBlur}
                onChange={(e)=>{setFromTime(e.target.value)}}
                value={fromTime}
                name="fromtime"
                error={!!touched.fromtime && !!errors.fromtime}
                helperText={touched.fromtime && errors.fromtime}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='time']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                    transform: 'translate(0, 1.5px)', // Move the label up slightly
                  },
                }}
              />


                <TextField
                fullWidth
                variant="filled"
                type="time"
                label="To Time"
                onBlur={handleBlur}
                onChange={(e)=>{setToTime(e.target.value)}}
                value={toTime}
                name="totime"
                error={!!touched.totime && !!errors.totime}
                helperText={touched.totime && errors.totime}
                inputProps={{ style: inputStyle }}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='time']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                    
                  },
                }}
              /> 
       
            </Box>



            <Box display="flex" justifyContent="end" mt="20px" >
              <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit}>
                Update Coupon
              </Button>
            </Box>
          </form>
        )}
      </Formik>
        )
              }
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // title: yup.string().required("required"),
  // description: yup.string().required("required"),
  // fromdate: yup.string().required("required"),
  // time: yup.string().required("required"),
  // todate: yup.string().required("required"),
  // place: yup.string().required("required"),
  // city: yup.string().required("required"),
  // address: yup.string().required("required"),
  // host: yup.string().required("required"),
  // age:yup.string().required("required"),
  // terms: yup.string().required("required"),
  // artist:yup.string().required("required"),
  // image: yup.string().required("required"),
  // price: yup.string().required("required"),
  // category: yup.string().required("required"),
  // language: yup.string().required("required"),
  // ticket_types: yup.string().required("required"),  

});
const initialValues = {
  code: "",
  discount_percentage: "",
  discount_value: "",
  limit:"",
  valid_from:"",
  valid_to:"",
  totime:"",
  fromtime:"",
};

export default EditCoupon;